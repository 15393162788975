import React, { memo } from 'react';
import BrandLogo from '../../../assets/images/solx-common/logo.png'
import { logoAlt } from '../../../utils/constant';

/**
 * @function Logo 
 * @returns Logo component
 */
const Logo = ({ classes, ...props }) => {
  return (
    <img src={BrandLogo} alt={logoAlt} className={classes} {...props} />
  )
}

export default memo(Logo);
