import axios from 'axios';

//creating a variable axiosInstance with axios events
const httpInstance = axios.create();

//middleware for httpInstance requests
httpInstance.interceptors.request.use(async(request) => {
  return request;
}, function (error) {
  return Promise.reject(error);
});

//middleware for httpInstance response
httpInstance.interceptors.response.use(response => {
  return response;
}, error => {
  return Promise.reject(error);
});

export default httpInstance;