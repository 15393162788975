import { httpResponseFormat } from "../../utils/httpResponseFormat";
import httpRequest from "../../utils/httpRequest";

/**
 * @function userDataApi for fetching current user details
 * @returns user data
 */
export const userDataApi = async () => {
  try {
    let userData = await httpRequest.get("userDetailsUrl", {}, "");
    if (userData.status === 200) {
      return httpResponseFormat(
        false,
        userData.data.user ? userData.data.user : {},
        "User data fetched successfully"
      );
    }
    return httpResponseFormat(false, {}, "User data fetched successfully");
  } catch (error) {
    console.log("erer", error);
    return httpResponseFormat(
      true,
      {},
      "Not able to fetch user data, please try again"
    );
  }
};

export const userApprovalApi = async (data) => {
  try {
    let userData = await httpRequest.post("userApproval", data, {}, "");
    if (userData.status === 200) {
      return httpResponseFormat(false, {}, "Approved successfully");
    }
    return httpResponseFormat(false, {}, "Internal error");
  } catch (error) {
    console.log("erer", error);
    return httpResponseFormat(
      true,
      {},
      error.response && error.response.data && error.response.data.message
    );
  }
};

export const userBlockApi = async (data) => {
  try {
    let userData = await httpRequest.post("userBlock", data, {}, "");
    if (userData.status === 200) {
      return httpResponseFormat(false, {}, "Action done successfully");
    }
    return httpResponseFormat(false, {}, "Internal error");
  } catch (error) {
    return httpResponseFormat(
      true,
      {},
      error.response && error.response.data && error.response.data.message
    );
  }
};

export const userViewApi = async (check) => {
  try {
    let userData = await httpRequest.get("userViewUrl", {}, check);
    if (userData.status === 200) {
      return httpResponseFormat(
        false,
        userData.data ? userData.data : {},
        "User data fetched successfully"
      );
    }
    return httpResponseFormat(true, {}, "User data not successfully fetched");
  } catch (error) {
    return httpResponseFormat(
      true,
      {},
      "Not able to fetch user data, please try again"
    );
  }
};

export const userDetailedView = async (params) => {
  try {
    let userData = await httpRequest.get("userDetailedViewUrl", {}, params);
    if (userData.status === 200) {
      return httpResponseFormat(
        false,
        userData.data.data ? userData.data.data : {},
        "User data fetched successfully"
      );
    }
    return httpResponseFormat(false, {}, "User data fetched successfully");
  } catch (error) {
    console.log("erer", error);
    return httpResponseFormat(
      true,
      {},
      "Not able to fetch user data, please try again"
    );
  }
};
