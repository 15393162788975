import React from 'react';

const Login = React.lazy(() => import('../views/auth/Login'));
// const Signin1 = React.lazy(() => import('../Demo/Authentication/SignIn/SignIn1'));
// const ResetPassword1 = React.lazy(() => import('../Demo/Authentication/ResetPassword/ResetPassword1'));
// const ChangePassword = React.lazy(() => import('../Demo/Authentication/ChangePassword'));
// const TabsAuth = React.lazy(() => import('../Demo/Authentication/TabsAuth'));
const Error403 = React.lazy(() => import('../Demo/Maintenance/Error403'));
// const Error404 = React.lazy(() => import('../Demo/Maintenance/Error404'));
// const Error405 = React.lazy(() => import('../Demo/Maintenance/Error405'));
// const Error500 = React.lazy(() => import('../Demo/Maintenance/Error500'));
// const OfflineUI = React.lazy(() => import('../Demo/Maintenance/OfflineUI'));

const route = [
    { path: '/login', exact: true, name: 'Login', component: Login },
    { path: '/maintenance/error-403', exact: true, name: 'Error 403', component: Error403 },
    // { path: '/maintenance/error-404', exact: true, name: 'Error 403', component: Error404 },
    // { path: '/maintenance/error-405', exact: true, name: 'Error 403', component: Error405 },
    // { path: '/maintenance/error-500', exact: true, name: 'Error 403', component: Error500 },
    // { path: '/maintenance/offline-ui', exact: true, name: 'Offline UI', component: OfflineUI },
];

export default route;