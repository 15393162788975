import { useMemo, useState, useEffect, useCallback } from 'react';
import storage from '../../utils/storage';
import { userDataApi } from './UserApi';

const UserHook = () => {

  const [userData, setUserData] = useState({});
  const [splashLoader, setSplashLoader] = useState(true);

  const userActions = useMemo(() => ({
    userDetails: (data) => {
      setUserData(prevState => ({
        ...prevState, ...data
      }));
    },
    logout: async () => {
      setUserData({});
      storage.removeAllToken()
    }
  }), []);

  /**
   * @function authCheck
   * token check
   * user data api call
   * splash management
   */
  const authCheck = useCallback(async () => {
    let token = storage.getAccessToken();
    if (token) {
      //For User Details
      let initialUserDetails = await userDataApi();
      if (!initialUserDetails.error && Object.keys(initialUserDetails.data).length > 0) {
        setUserData(initialUserDetails.data);
      } else {
        // Toaster(initialUserDetails.message);
      }
    }
    setSplashLoader(false);
  }, []);

  useEffect(() => {
    
    /**
     * @function initialAsyncCalls wraps initial functions
     */
     async function initialAsyncCalls() {
      // calls authCheck function
      await authCheck();
    }

    initialAsyncCalls();

    return () => {
      //unmounting the function
      initialAsyncCalls();
    }

  }, []);

  return { userData, userActions, splashLoader }

};

export default UserHook;