import React from 'react';
import { Row } from 'react-bootstrap';
import Logo from '../Logo';

const SplashLoader = () => {

  const spinnerVariants = [
    'primary',
    'secondary',
    'success'
  ];
  const basicSpinnerGrowing = spinnerVariants.map((variant, idx) => (
      <div key={idx} className={['spinner-grow', 'mr-1', 'ml-4', 'text-'+variant].join(' ')} role="status">
          <span className="sr-only">Loading...</span>
      </div>
  ));

  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", background: "rgb(224 224 224)", height: "100vh", width: "100%"}}>
      <Logo />
      {basicSpinnerGrowing}
    </div>
  );
};

export default SplashLoader;
