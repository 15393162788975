import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from '@redux-saga/core';
import { rootReducer } from './root.reducer';
import { rootSaga } from './root.saga';

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}): compose;

const middlewares = [sagaMiddleware];

const enchancer = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(rootReducer(), initialState, enchancer);

sagaMiddleware.run(rootSaga);

export default store;