import { storage } from "./constant";
import { getCookie, setCookie, deleteAllCookies, deleteCookie } from "./cookies";

const { auth, refresh } = storage;

export default {
  storeAccessToken: (accessToken) => {
    setCookie(auth, accessToken);
  },
  getAccessToken: () => {
    return getCookie(auth);
  },
  removeAccessToken: () => {
    return deleteCookie(auth);
  },
  removeAllToken: () => {
    return deleteAllCookies();
  }
}