/**
 * @function httpResponseFormat is global function for http call response
 * @param {*} error have error status, its a boolean value
 * @param {*} data have data to be returned in object format
 * @param {*} message is for status message
 * @returns an object 
 */
 export const httpResponseFormat = (error = false, data = {}, message = '') => {
  return {
    error, data, message
  }
};