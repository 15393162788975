import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App/index";
import "./assets/scss/style.scss";
import config from "./config";
import * as serviceWorker from "./serviceWorker";
import store from "./state/store";
import { ErrorBoundary } from "./views/ErrorBoundary";

const app = (
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
