import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loadable from "react-loadable";

import "../../node_modules/font-awesome/scss/font-awesome.scss";

import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../navigation/publicRoutes";
import SplashLoader from "./components/common/Loader/SplashLoader";
import UserHook from "../state/user/UserHook";
import { UserContext } from "../state/user/UserContext";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

const App = () => {
  const { userData, userActions, splashLoader } = UserHook();

  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} />}
      />
    ) : null;
  });

  const renderScreen = () => {
    if (splashLoader) {
      return <SplashLoader />;
    } else {
      return (
        <Suspense fallback={<Loader />}>
          <Switch>
            <UserContext.Provider value={{ userActions, userData }}>
              {Object.keys(userData).length <= 0 ? (
                <>
                  {menu}
                  <Redirect from="/" to="/login" />
                </>
              ) : (
                <Route path="/" component={AdminLayout} />
              )}
            </UserContext.Provider>
          </Switch>
        </Suspense>
      );
    }
  };

  return <ScrollToTop>{renderScreen()}</ScrollToTop>;
};

export default App;
